@import "@radix-ui/colors/black-alpha.css";
@import "@radix-ui/colors/violet.css";

.SliderRoot {
	position: relative;
	display: flex;
	align-items: center;
	user-select: none;
	touch-action: none;
	width: 100%;
	height: 15px;
}

.SliderTrack {
	background-color: var(--black-a2);
	position: relative;
	flex-grow: 1;
	border-radius: 9999px;
	height: 5px;
}

.SliderTrack::after {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: var(--accent-1);
  top: 50%;
  left: 50%;
  border: 1px solid var(--accent-track);
  translate: -50% -50%;
}

.SliderRange {
	position: absolute;
	background-color: var(--orange-9);
	border-radius: 9999px;
	height: 100%;
}

.SliderRange::before {
  content: 'Urgente';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -30px;
  font-size: 12px;
}

.SliderThumb {
	display: block;
	width: 20px;
	height: 20px;
	background-color: var(--accent-1);
  border: 2px solid var(--accent-track);
	box-shadow: 0 0 10px var(--black-a7);
	border-radius: 100%;
  outline: none;
}

.SliderThumb:hover {
	scale: 1.2;
}

.SliderThumb:focus {
	outline: none;
	/* box-shadow: 0 0 0 5px var(--black-a8); */
}
