.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  align-items: stretch;
}

.wrapper-signin {
  width: 40%;
}

.wrapper-animation {
  background-color: var(--gray-3);
  width: 60%;
}
