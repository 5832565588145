.file-container {
  padding: 1.5rem;
}

.file-upload {
  border: 1px dashed var(--gray-7);
  border-radius: max(var(--radius-3), var(--radius-full));
  cursor: pointer;
}

.input-file {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

